// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-addresses-js": () => import("./../../../src/pages/account/addresses.js" /* webpackChunkName: "component---src-pages-account-addresses-js" */),
  "component---src-pages-account-email-preferences-js": () => import("./../../../src/pages/account/email-preferences.js" /* webpackChunkName: "component---src-pages-account-email-preferences-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-loyalty-js": () => import("./../../../src/pages/account/loyalty.js" /* webpackChunkName: "component---src-pages-account-loyalty-js" */),
  "component---src-pages-account-orders-js": () => import("./../../../src/pages/account/orders.js" /* webpackChunkName: "component---src-pages-account-orders-js" */),
  "component---src-pages-account-payment-methods-js": () => import("./../../../src/pages/account/payment-methods.js" /* webpackChunkName: "component---src-pages-account-payment-methods-js" */),
  "component---src-pages-account-recent-items-js": () => import("./../../../src/pages/account/recent-items.js" /* webpackChunkName: "component---src-pages-account-recent-items-js" */),
  "component---src-pages-account-wishlists-js": () => import("./../../../src/pages/account/wishlists.js" /* webpackChunkName: "component---src-pages-account-wishlists-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/Catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-changepassword-js": () => import("./../../../src/pages/changepassword.js" /* webpackChunkName: "component---src-pages-changepassword-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgotpassword-js": () => import("./../../../src/pages/forgotpassword.js" /* webpackChunkName: "component---src-pages-forgotpassword-js" */),
  "component---src-pages-giftcertificates-check-balance-js": () => import("./../../../src/pages/giftcertificates/check-balance.js" /* webpackChunkName: "component---src-pages-giftcertificates-check-balance-js" */),
  "component---src-pages-giftcertificates-index-js": () => import("./../../../src/pages/giftcertificates/index.js" /* webpackChunkName: "component---src-pages-giftcertificates-index-js" */),
  "component---src-pages-giftcertificates-redeem-js": () => import("./../../../src/pages/giftcertificates/redeem.js" /* webpackChunkName: "component---src-pages-giftcertificates-redeem-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/Product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-returns-request-js": () => import("./../../../src/pages/returns-request.js" /* webpackChunkName: "component---src-pages-returns-request-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-templates-bc-catalog-js": () => import("./../../../src/templates/BCCatalog.js" /* webpackChunkName: "component---src-templates-bc-catalog-js" */),
  "component---src-templates-bc-product-js": () => import("./../../../src/templates/BCProduct.js" /* webpackChunkName: "component---src-templates-bc-product-js" */),
  "component---src-templates-builder-page-js": () => import("./../../../src/templates/BuilderPage.js" /* webpackChunkName: "component---src-templates-builder-page-js" */),
  "component---src-templates-wp-author-js": () => import("./../../../src/templates/WPAuthor.js" /* webpackChunkName: "component---src-templates-wp-author-js" */),
  "component---src-templates-wp-blog-js": () => import("./../../../src/templates/WPBlog.js" /* webpackChunkName: "component---src-templates-wp-blog-js" */),
  "component---src-templates-wp-category-js": () => import("./../../../src/templates/WPCategory.js" /* webpackChunkName: "component---src-templates-wp-category-js" */),
  "component---src-templates-wp-page-js": () => import("./../../../src/templates/WPPage.js" /* webpackChunkName: "component---src-templates-wp-page-js" */),
  "component---src-templates-wp-post-js": () => import("./../../../src/templates/WPPost.js" /* webpackChunkName: "component---src-templates-wp-post-js" */),
  "component---src-templates-wp-tag-js": () => import("./../../../src/templates/WPTag.js" /* webpackChunkName: "component---src-templates-wp-tag-js" */)
}

