import React, { createContext, useContext } from 'react';

import AuthContext from './AuthProvider';
import WishlistContext from './WishlistProvider';

const BindContext = createContext();

export const BindProvider = ({ children }) => {
    const auth = useContext(AuthContext);
    const authAddSupport = auth && auth.addSupport
    const wishlist = useContext(WishlistContext);

    const init = () => {
        authAddSupport('wishlist', wishlist);
    }

    return (
        <BindContext.Provider value={{init}}>{children}</BindContext.Provider>
    );
};
    
export default BindContext;