import React, { createContext, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import GraphQLContext from './GraphQLProvider';
import { bcApi } from '../helpers/bigcommerce'
import { registerAccount, initLogin, validateLogin, confirmPassword } from '../helpers/auth'
import { getStorage, removeStorage, setStorage, infoStorage } from '../helpers/general'

const AuthContext = createContext();

const initialState = {
  userChecked: false,
  isLoggedIn: false,
  customerId: 0,
  email: '',
  support: {},
};

export const AuthProvider = ({ children, bcPath, clientId }) => {
  const graphqlCxt = useContext(GraphQLContext);
  const graphqlQuery = graphqlCxt && graphqlCxt.query
  const [state, setState] = useState(initialState);

  /**
   * This data keeps changing if a customer group has discounts assigned or not.
   * If some are assigned, there is an additional level with `_1` under discount_rules.
   * GraphQL is doesn't expect schemas to change dynamically like that which is a problem here.
   */
  const customerGroupData = useStaticQuery(graphql`
    query {
      allBigCommerceCustomerGroups {
        edges {
          node {
            name
            id: bigcommerce_id
            discount_rules {
              amount
              method
              type
            }
            is_default
            is_group_for_guests
          }
        }
      }
    }
  `)
  const customerGroups = {};
  customerGroupData?.allBigCommerceCustomerGroups.edges.map(group => {
    customerGroups[group.node.id] = group.node;

    return true;
  });

  const getCustomerGroup = (customerGroupId) => {
    if (customerGroups && customerGroupId in customerGroups) {
      if (customerGroups[customerGroupId].name.startsWith('Retail')) {
        return 'retail';
      }
    }

    return 'guest';
  }

  const addSupport = (key, value) => {
    const support = state.support;
    if (!(key in support)) {
      support[key] = value;
      setState({...state, support});
    }
  }

  const checkLoggedIn = () => {
    const sessionData = infoStorage('_loggedIn');
    if (sessionData) {
      validateLogin(sessionData).then(response => {
        if (String(response.status).startsWith('2') && response.response.token) {
          setStorage('_loggedIn', response.response.token, sessionData.storage === 'sessionStorage' ? true : false);

          setState({
            ...state,
            ...response.response.object
          })
          // resolve(response)
        } else {
          clearStorage();setState({...state, userChecked: true, onFetchLogin: false})
        }
      }).catch(error => {
        console.log(error);
        clearStorage();setState({...state, userChecked: true, onFetchLogin: false})
      })
    } else {
      clearStorage();setState({...state, userChecked: true, onFetchLogin: false})
    }
  }

  // eslint-disable-next-line
  useEffect(() => checkLoggedIn(), []);

  const refreshData = () => {
    if (state.isLoggedIn) {
      // fetchLogin(state.customerId);
      checkLoggedIn();
    }
  }

  const afterAuth = async (customerId, customerGroup) => {
    const afterAuthCalls = getStorage('_afterAuth');
    let actionResult = false;

    if (afterAuthCalls) {
      const afterAuthCallsObject = JSON.parse(afterAuthCalls);
      if (afterAuthCallsObject.action === 'saveWishlist') {
        // console.log("_afterAuth should be removed");
        removeStorage('_afterAuth')
        actionResult = await state.support.wishlist.saveWishlist(afterAuthCallsObject.name, true, customerId)
      }
    }

    if (typeof window !== 'undefined' && typeof document !== 'undefined' && !actionResult) {
      // console.log("Redirecting normally");
      const browserLastPage = !document.referrer.startsWith(process.env.SITE_URL) ? process.env.SITE_URL : (document.referrer.indexOf('logout') ? process.env.SITE_URL : document.referrer);
      const userLastPage = getStorage('lastPage');
      const forcedPage = getStorage('forcedPage');
      const returnUrl = forcedPage || userLastPage || browserLastPage;
      removeStorage('forcedPage');
      
      window.location = returnUrl;
    }

    return actionResult
  }

  const logout = () => {
    setState(initialState)
    clearStorage(true);

    bcApi('carts').then(async ({response}) => {
      if (response && 'data' in response && 'id' in response.data) {
        await bcApi(`carts/${response.data.id}`, 'PUT', {'customer_id': 0});
      }
      graphqlQuery(`
        mutation Logout {
          logout {
            result
          }
        }
      `).then(response => {
        if (typeof window !== 'undefined') {
          window.location = 'https://checkout.spencil.com.au/login.php?action=logout';
        }
      });
    });
  }

  const clearStorage = (fullClear) => {
    removeStorage('_loggedIn')
    removeStorage('_isPending')
    if (fullClear) {
      removeStorage('__jammcd');
    }
  }

  const checkPassword = (email, password) => {
    return new Promise((res, rej) => {
      confirmPassword(email, password).then(response => {
        res(response);
      }).catch(e => {
        rej(e);
      })
    })
  }

  const login = (email, password, remember) => {
    return new Promise((res, rej) => {
      initLogin(email, password).then(async response => {
        // console.log("initLogin response", response);
        if (String(response.status).startsWith('2') && response.response.token) {
          setStorage('_loggedIn', response.response.token, remember ? false : true);

          const customerGroup = getCustomerGroup(response.response.object.object.customer_group_id);

          setState({
            ...state,
            ...response.response.object
          })
          
          await afterAuth(response.response.object.customerId, customerGroup);

          res(response)
        } else {
          rej(response);
        }
      }).catch(e => rej(e));
    });
  }

  // const isWholesale = () => {
  //   const preDetermined = getStorage('_isWholesale');
  //   if (preDetermined) {
  //     return typeof preDetermined === 'string' ? (preDetermined === 'true' ? true : false) : preDetermined;
  //   } else {
  //     const determine = (state.userChecked === true && state.isLoggedIn === true && state.customerGroup === 'wholesale') ? true : false;
  //     if (determine) {
  //       setStorage('_isWholesale', true);
  //       removeStorage('_isPending');
  //       return true;
  //     } else {
  //       removeStorage('_isWholesale');
  //       return false;
  //     }
  //   }
  // }

  // const isWholesalePending = () => {
  //   const preDetermined = getStorage('_isPending');
  //   if (preDetermined) {
  //     return preDetermined;
  //   } else {
  //     const determine = (state.userChecked === true && state.isLoggedIn === true && customerGroups[state.object.customer_group_id].name.startsWith('Pending Wholesale')) ? true : false;
  //     if (determine) {
  //       setStorage('_isPending', true);
  //       removeStorage('_isWholesale');
  //       return true;
  //     } else {
  //       removeStorage('_isPending');
  //       return false;
  //     }
  //   }
  // }

  /* TODO: To be ported into the auth file */
  const forgotPassword = (email) => {
    return new Promise((res) => {
      fetch(`${bcPath}/login.php?action=send_password_email`, {
        body: `email=${email}`,
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "post",
      }).then(result => {
        // console.log(result);
        res(true)
      }).catch(error => {
        // Endpoint returns failed however the process is actually successful. As such, we can't effectively
        // console.log(error);
        res(true)
      });
    });
  }

  const changePassword = (fields, customerId, token) => {
    const bcApiBody = JSON.stringify([fields]);
    fetch(`${bcPath}/login.php?action=save_new_password&c=${customerId}&t=${token}`, {
      body: bcApiBody,
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "post",
    }).then(result => {
      console.log(result);
    });
  }

  const signup = (fields) => {
    return new Promise((res, rej) => {
      registerAccount(fields).then(async response => {
        if (String(response.status).startsWith('2')) {
          if (!fields.cart && response.response.token) {
            // Log user in as its a free account
            setStorage('_loggedIn', response.response.token);

            setState({
              ...state,
              ...response.response.object
            })
            
            const customerGroup = getCustomerGroup(response.response.object.object.customer_group_id);
            await afterAuth(response.response.object.customerId, customerGroup);

            res(response);
          } else {
            res(response);
          }
        } else {
          rej(response);
        }
      }).catch(e => rej(e));
    });
  }

  return (
    <AuthContext.Provider value={{
        state,
        setState,
        addSupport,
        refreshData,
        login,
        logout,
        signup,
        checkPassword,
        forgotPassword,
        changePassword,
    }}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;