import React from 'react';

import { GraphQLProvider } from './src/context/GraphQLProvider';
import { AuthProvider } from './src/context/AuthProvider';
import { WishlistProvider } from './src/context/WishlistProvider';
import { PriceProvider } from './src/context/PriceProvider';
import { CartProvider } from './src/context/CartProvider';
import { BindProvider } from './src/context/BindProvider';
import { ReviewProvider } from './src/context/ReviewProvider';
require('dotenv').config()

export const disableCorePrefetching = () => true;

export const wrapRootElement = ({ element }) => (
  <GraphQLProvider bcPath={process.env.BC_PATH} domain={window.location.hostname === 'push-stage--spencil.netlify.app' ? process.env.STAGING_SITE_URL : process.env.SITE_URL}>
    <AuthProvider bcPath={process.env.BC_PATH} clientId={process.env.BC_CLIENT_ID}>
      <WishlistProvider>
        <PriceProvider>
          <CartProvider>
            <BindProvider>
              <ReviewProvider>
                {element}
              </ReviewProvider>
            </BindProvider>
          </CartProvider>
        </PriceProvider>
      </WishlistProvider>
    </AuthProvider>
  </GraphQLProvider>
);
