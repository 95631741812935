import React, { createContext, useContext, useState, useEffect } from 'react';
import { get, values, sumBy } from 'lodash';
import AuthContext from './AuthProvider';
import { bcApi } from '../helpers/bigcommerce';
import { dataLayerPush } from '../helpers/general';
import { useStaticQuery, graphql } from 'gatsby';

const CartContext = createContext();

const initialState = {
  cartLoading: true,
  cartError: false,
  cart: {
    currency: {
      code: 'AUD'
    },
    cartAmount: 0,
    lineItems: {},
    numberItems: 0,
    redirectUrls: {}
  },
  shippingMethod: 'delivery',
  donationMessage: false,
  donationAdded: false
};

const getItemsIdInCart = lineItems => {
  return values(lineItems)
    .reduce((acc, curr) => {
      return [
        ...acc,
        ...curr.map(ite => ({ item_id: ite.id, quantity: ite.quantity }))
      ];
    }, [])
    .filter(x => x);
};

export const CartProvider = ({ children }) => {
  const auth = useContext(AuthContext);
  const customer = auth && auth.state;
  const [state, setState] = useState(initialState);
  const [notifications, updateNotifications] = useState([]);

  const donationData = useStaticQuery(graphql`
      query {
        allBuilderModels {
          donation {
            data {
              donationMessage
              donationLabel
              donationProductSku
              donationMoreInfo
            }
          }
        }
      }
  `);

  const addDonation = (productId, optionId) => {
    return new Promise(async (res) => {
      if (state.donationAdded) {
        // Is added - remove
        const lineItem = state.cart.lineItems.physical_items.find(item => item.product_id === productId);
        removeItemFromCart(lineItem.id).then(() => {
          res(true);
        });
      } else {
        // Not added - add
        addToCart(productId, optionId).then(() => {
          res(true);
        });
      }

    });
  }

  const removeDonation = (productId, optionId) => {
    return new Promise(async (res) => {
      if (state.donationAdded) {
        // Is added - remove
        const lineItem = state.cart.lineItems.physical_items.find(item => item.product_id === productId);
        removeItemFromCart(lineItem.id).then(() => {
          res(true);
        });
      }

    });
  }

  const addNotification = (text, type = 'notify') => {
    updateNotifications([...notifications, { text, type, id: Date.now() }]);
  };

  const removeNotification = id => {
    updateNotifications(notifications.filter(ntfy => ntfy.id !== id));
  };

  const fetchCart = async () => {
    setState({ ...state, cartFetched: false });
    return await bcApi('carts')
      .then(({ response }) => {
        if (response) {
          return refreshCart(response);
        } else {
          setState({ ...state, cartFetched: true });
          return false;
        }
      })
      .catch(error => {
        setState({ ...state, cartLoading: false, cartFetched: false, cartError: error });
        return false;
      });
  };

  // eslint-disable-next-line
  useEffect(() => fetchCart(), []);

  const calculateNumberItems = lineItems => {
    const {
      physical_items = [],
      digital_items = [],
      custom_items = [],
      gift_certificates = []
    } = lineItems || {};
    const numberPhysical = sumBy(physical_items, ite => ite.quantity) || 0;
    const numberDigital = sumBy(digital_items, ite => ite.quantity) || 0;
    const numberCustom = sumBy(custom_items, ite => ite.quantity) || 0;
    const numberGift = sumBy(gift_certificates, ite => ite.quantity) || 0;
    return numberPhysical + numberDigital + numberCustom + numberGift;
  };

  const refreshCart = response => {
    if (response.status === 204 || response.status === 404) {
      setState({ ...state, cartLoading: false });
    } else {
      return updateState(response);
    }
  };

  const refreshCheckout = (cartId, theState) => {
    bcApi(`checkouts/${cartId}`).then(response => {
      if (response.status === 200) {
        setState({...theState, checkout: response.response.data});
      }
    });
  };

  const initCheckout = async () => {
    const {response, status} = await bcApi(`checkouts/${state.cart.cartId}`);
    if (status === 200) {
      setState({...state, checkout: response.data});
      return {...state, checkout: response.data};
    }
    return state;
  };

  const clearCart = () => {
    const cartId = 'cartId' in state.cart ? state.cart.cartId : null;
    if (cartId) {
      bcApi(`carts/${cartId}`, 'DELETE').then(response => {
        console.log(response);
        setState({ ...state, cartLoading: false });
        if (typeof window !== 'undefined') {
          window.location.reload();
        }
      })
    }
  }

  const updateState = (response) => {
    return new Promise(res => {
      const lineItems = response.data.line_items;
      const cartAmount = response.data.cart_amount;
      const baseAmount = response.data.base_amount;
      const currency = response.data.currency;
      const cartId = response.data.id;
      const coupons = response.data.coupons;
      let donationProduct = false;
      let donationAdded = false;
      let donationAddedVariant = false;
      let donationAddedImage = false;
      let donationAddedAmount = false;
      let donationMessage = false;
      let donationLabel = false;
      let donationMoreInfo = false;

      // Fetch additional product data
      const productIds = response.data.line_items.physical_items.map(product => product.product_id);
      bcApi(`catalog/products?id:in=${productIds.join(',')}`).then(async productData => {


        const productInfo = {};
        productData.response.data.map(product => {
          productInfo[product.id] = product;
          return true;
        });
        const tax = parseFloat((productData.response.data.filter(i => i.tax_class_id !== 1).reduce((a, b) => a + (b.calculated_price / 11), 0)).toFixed(2))
        const taxAmount = tax ? tax : 0;

        if (donationData && donationData.allBuilderModels.donation.length) {
          const donationProductData = await bcApi(`catalog/products?include=variants,options&sku:in=${donationData?.allBuilderModels.donation[0].data.donationProductSku}`);

          donationProduct = donationProductData.response.data[0];

          // Set bool value if found
          // console.log("Cart line items", lineItems);
          if (lineItems && lineItems.physical_items.length > 0) {
            donationAdded = typeof lineItems.physical_items.find(item => item.product_id === donationProduct.id) !== 'undefined';
            const donationAddedProduct = lineItems.physical_items.find(item => item.product_id === donationProduct.id);
            donationAddedVariant = donationAddedProduct && donationAddedProduct.variant_id ? donationAddedProduct.variant_id : undefined;
            donationAddedImage = donationAddedProduct ? donationAddedProduct.image_url: undefined;
            donationAddedAmount = donationAddedProduct ? donationAddedProduct.sale_price: undefined;
          }

          donationMessage = donationData ? donationData.allBuilderModels.donation[0].data.donationMessage : false;

          donationLabel = donationData ? donationData.allBuilderModels.donation[0].data.donationLabel : false;

          donationMoreInfo = donationData ? donationData.allBuilderModels.donation[0].data.donationMoreInfo : false;
        }

        const newState = {
          ...state,
          cartLoading: false,
          addingToCart: false,
          updatingItem: false,
          coupons,
          cart: {
            cartId,
            currency,
            cartAmount,
            lineItems,
            productInfo,
            taxAmount,
            baseAmount,
            numberItems: calculateNumberItems(lineItems),
            redirectUrls: response.data.redirect_urls || state.cart.redirectUrls
          },
          donationProduct: donationProduct,
          donationMessage: donationMessage,
          donationLabel: donationLabel,
          donationMoreInfo: donationMoreInfo,
          donationAdded,
          donationAddedVariant,
          donationAddedImage,
          donationAddedAmount,
        };
        setState(newState);
        refreshCheckout(cartId, newState);
        res(true);
      });
    });
  }

  const addToCart = (productId, variantId, retry, quantity = 1, customPrice) => {
    return new Promise((res, rej) => {
      setState({ ...state, addingToCart: productId });
      const bcApiBody = JSON.stringify({
        line_items: [
          {
            quantity: parseInt(quantity, 10),
            product_id: parseInt(productId, 10),
            variant_id: parseInt(variantId, 10),
            list_price: customPrice,
          }
        ]
      });
      bcApi('carts/items', 'POST', bcApiBody)
        .then(({ response, status }) => {
          if (status === 404 && !retry) {
            // re create a cart if cart was destroyed
            // TODO: Clean this up as the line_items could be added here and prevent an extra call needing to be made
            const newCartBody = {};
            if (customer.isLoggedIn) {
              newCartBody.customer_id = customer.customerId;
            }
            return bcApi('carts', "POST", newCartBody).then(() =>
              addToCart(productId, variantId, true, quantity, customPrice)
            );
          }

          if ('data' in response) {
            status < 300 && addNotification('Item added successfully');

            // If we have a logged in customer and the cart is not bound to that customer, bind it now
            if (response.data.customer_id !== customer.customerId) {
              bcApi('carts', 'PUT', {customer_id: customer.customerId}).then(response => {
                refreshCart(response.response);
              });
            }

            const addedProduct = [];
            const justUnoProduct = [];
            const itemTypes = Object.keys(response.data.line_items);
            itemTypes.map(itemType => {
              response.data.line_items[itemType].map(item => {
                if (item.product_id === productId) {
                  addedProduct.push({
                    id: `${item.product_id}_${item.variant_id}`,
                    title: item.name,
                    price: item.sale_price || item.price || 0,
                    quantity: item.quantity,
                    sku: item.sku
                  });

                  justUnoProduct.push({
                    productid: `${item.product_id}_${item.variant_id}`,
                    variantid: "",
                    sku: item.sku,
                    name: item.name,
                    price: item.sale_price || item.price || 0,
                    quantity: item.quantity
                  })
                }
                return true;
              });
              return true;
            });
            dataLayerPush('add_to_cart', null, addedProduct);

            const urlarr = window.location.pathname.split('/');
            const curpage = urlarr[1];
            let pageType = '';

            if (curpage === 'shop') {
               pageType = 'category';
            } else if (curpage === 'product') {
               pageType = 'product';
            } else {
               pageType = curpage;
            }

            if (typeof window !== undefined && window.juapp) {
              window.juapp('local', 'pageType', pageType);
              window.juapp('local', 'prodId', productId);
              window.juapp('local', 'custId', auth.customerId);
            }

            updateState(response).then(() => {
              res(true);
            });
          } else if (response.status > 300 && 'title' in response) {
            setState({ ...state, addingToCart: false, addToCartError: response });
            rej(response.title);
          }

        })
        .catch(error => {
          setState({ ...state, addingToCart: false, addToCartError: error });
          rej(error);
        });
    });
  };

  const addAllToCart = (items, retry) => {
    return new Promise((res, rej) => {
      const lineItems = items.map(product => {
        if ('product_id' in product) {
          return {
            quantity: 1,
            product_id: parseInt(product.product_id, 10),
            variant_id: parseInt(product.variant_id, 10)
          };
        } else {
          return {
            quantity: 1,
            product_id: parseInt(product[0], 10),
            variant_id: parseInt(product[1], 10)
          };
        }
      });

      const bcApiBody = JSON.stringify({
        line_items: lineItems
      });
      bcApi('carts/items', 'POST', bcApiBody)
        .then(({ response, status }) => {
          if (status === 404 && !retry) {
            // re create a cart if cart was destroyed
            return bcApi('carts').then(() => addAllToCart(items, true));
          }

          if (status === 422) {
            rej(response);
            return;
          }
          status < 300 && addNotification('Item added successfully');

          const lineItems = response.data.line_items;
          const cartAmount = response.data.cart_amount;
          const baseAmount = response.data.base_amount;
          const currency = response.data.currency;
          const cartId = response.data.id;

          setState({
            ...state,
            addingToCart: false,
            // addedToCart: productId,
            cart: {
              cartId,
              currency,
              cartAmount,
              baseAmount,
              lineItems,
              numberItems: calculateNumberItems(lineItems),
              redirectUrls: response.data.redirect_urls
            }
          });
          res(true);
        })
        .catch(error => {
          setState({ ...state, addingToCart: false, addToCartError: error });
          rej(error);
        });
    });
  };

  const updateItemInCart = (itemId, updatedItemData) => {
    const bcApiBody = JSON.stringify(updatedItemData);
    // @see https://developer.bigcommerce.com/api-reference/store-management/carts/cart-items/updatecartlineitem
    bcApi(`carts/${state.cart.cartId}/items/${itemId}`, 'PUT', bcApiBody)
      .then(({ response }) => {
        refreshCart(response);
      })
      .catch(error => {
        setState({ ...state, cartLoading: false, cartError: error });
      });
  };

  const removeItemFromCart = itemId => {
    // @see https://developer.bigcommerce.com/api-reference/store-management/carts/cart-items/deletecartlineitem
    setState({ ...state, updatingItem: itemId });
    return new Promise((res, rej) => {
      bcApi(`carts/${state.cart.cartId}/items/${itemId}`, 'DELETE')
        .then(({ response, status }) => {
          // addNotification('Item removed successfully');
          if (status === 204) {
            setState({ ...initialState, cartLoading: false });
            return bcApi('carts').then(() => {
              /* do nothing */
            });
          }
          // addNotification('Item removed successfully');
          refreshCart(response).then(() => res(true));
        })
        .catch(error => {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(false);
        });
    });
  };

  const updateCartItemQuantity = (item, action) => {
    let newQuantity;
    if (['minus', 'plus'].indexOf(action) > -1) {
      newQuantity = item.quantity + (action === 'minus' ? -1 : 1);
    } else {
      newQuantity = action;
    }
    setState({ ...state, updatingItem: item.id });
    if (newQuantity < 1) {
      return removeItemFromCart(item.id);
    }
    let productVariantReferences = null;

    if (typeof item.product_id !== 'undefined') {
      productVariantReferences = {
        product_id: item.product_id,
        variant_id: item.variant_id
      };
    }

    updateItemInCart(item.id, {
      line_item: {
        quantity: newQuantity,
        ...productVariantReferences
      }
    });
  };

  const addCoupons = async coupon_code => {
    return new Promise(async (res, rej) => {
      const endpoint = `checkouts/${state.cart.cartId}/coupons`;
      const reqBody = { coupon_code };
      try {
        const { response, status } = await bcApi(endpoint, 'POST', reqBody);
        const coupons = get(response, 'data.coupons');
        if (status === 200 && coupons) {
          setState({
            ...state,
            coupons
          });
          res(true);
        } else {
          rej(response);
        }
      } catch (error) {
        setState({ ...state, cartLoading: false, cartError: error });
        rej(error);
      }
    });
  };

  const removeCoupons = async coupon_code => {
    const endpoint = `checkouts/${state.cart.cartId}/coupons/${coupon_code}`;
    try {
      const { response, status } = await bcApi(endpoint, 'DELETE');
      const coupons = get(response, 'data.coupons');
      if (status === 200 && coupons) {
        setState({
          ...state,
          coupons
        });
      }
    } catch (error) {
      console.log(error);
      setState({ ...state, cartLoading: false, cartError: error });
    }
  };

  const addConsignments = async shippingAddress => {
    const { cartId, lineItems } = state.cart;
    if (cartId) {
      const endpoint = `checkouts/${cartId}/consignments`;
      const req_body = [
        {
          shipping_address: shippingAddress,
          line_items: getItemsIdInCart(lineItems)
        }
      ];

      try {
        const { response, status } = await bcApi(endpoint, 'POST', req_body);
        if (status === 200) {
          setState({
            ...state,
            consignments: response.data.consignments
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const updateConsignments = async (shippingAddress, consignmentId) => {
    // PUT /checkouts/{checkoutId}/consignments/{consignmentId}
    const {
      cart: { cartId, lineItems }
    } = state;
    if (cartId) {
      const endpoint = `checkouts/${cartId}/consignments/${consignmentId}`;
      const req_body = {
        shipping_address: shippingAddress,
        line_items: getItemsIdInCart(lineItems)
      };
      try {
        const { status } = await bcApi(endpoint, 'PUT', req_body);
        if (status === 200) {
          setState({
            ...state,
            consignments: []
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const addGiftCertificates = async giftCertCode => {
    const { cartId } = state.cart;
    if (cartId) {
      const endpoint = `checkouts/${cartId}/gift-certificates`;
      // TODO: make this request to work
      try {
        const { response, status } = await bcApi(endpoint, 'POST', {
          giftCertificateCode: giftCertCode
        });
        console.log(status, response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <CartContext.Provider
      value={{
        state,
        fetchCart,
        addToCart,
        addAllToCart,
        addCoupons,
        removeCoupons,
        removeItemFromCart,
        updateCartItemQuantity,
        clearCart,
        notifications,
        addNotification,
        removeNotification,
        addConsignments,
        addGiftCertificates,
        updateConsignments,
        addDonation,
        removeDonation,
        initCheckout,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
